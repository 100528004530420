@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --color-base-black: 0 0% 0%;
  --opacity-8: 0.08;
  --opacity-16: 0.16;
  --shadow-spread-300: -4px;
  --shadow-spread-400: -2px;
  --shadow-lg-color-1: var(--color-base-black) / var(--opacity-16);
  --shadow-lg-color-2: var(--color-base-black) / var(--opacity-8);
  --shadow-lg: 0px 8px 12px var(--shadow-spread-300) hsl(var(--shadow-lg-color-1)),
    0px 4px 12px var(--shadow-spread-400) hsl(var(--shadow-lg-color-2));
}

@layer {
  @font-face {
    font-family: 'EarnInMori';
    font-display: swap;
    src:
      url('/dist/fonts/EarnInMori-Book.woff2') format('woff2'),
      url('/dist/fonts/EarnInMori-Book.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'EarnInMori';
    font-display: swap;
    src:
      url('/dist/fonts/EarnInMori-BookItalic.woff2') format('woff2'),
      url('/dist/fonts/EarnInMori-BookItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'EarnInMori';
    font-display: swap;
    src:
      url('/dist/fonts/EarnInMori-Regular.woff2') format('woff2'),
      url('/dist/fonts/EarnInMori-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'EarnInMori';
    font-display: swap;
    src:
      url('/dist/fonts/EarnInMori-RegularItalic.woff2') format('woff2'),
      url('/dist/fonts/EarnInMori-RegularItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'EarnInMori';
    font-display: swap;
    src:
      url('/dist/fonts/EarnInMori-SemiBold.woff2') format('woff2'),
      url('/dist/fonts/EarnInMori-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'EarnInMori';
    font-display: swap;
    src:
      url('/dist/fonts/EarnInMori-SemiBoldItalic.woff2') format('woff2'),
      url('/dist/fonts/EarnInMori-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'EarnInMori';
    font-display: swap;
    src:
      url('/dist/fonts/EarnInMori-Bold.woff2') format('woff2'),
      url('/dist/fonts/EarnInMori-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'EarnInMori';
    font-display: swap;
    src:
      url('/dist/fonts/EarnInMori-BoldItalic.woff2') format('woff2'),
      url('/dist/fonts/EarnInMori-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'EarnInMori';
    font-display: swap;
    src:
      url('/dist/fonts/EarnInMori-ExtraBold.woff2') format('woff2'),
      url('/dist/fonts/EarnInMori-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'EarnInMori';
    font-display: swap;
    src:
      url('/dist/fonts/EarnInMori-ExtraBoldItalic.woff2') format('woff2'),
      url('/dist/fonts/EarnInMori-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
  }
}
