.button.get-started-main {
  height: 50px;
  width: 456px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

div.get-started-web-funnel {
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 0px;
  width: fit-content;
}

.footer-container .get-started-web-funnel.mobile {
  display: block;
  margin: 0px 0 35px 0;
  width: 100%;

  &.nn {
    display: none;
  }
}

/*** Blog CTA ***/
.blog-cta {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 900;
  background-color: #ffe33b;
  animation: blog-cta-fade-in 1s ease;
  animation-fill-mode: forwards;
  animation-delay: 3s;
  transform: translateY(100%);
  opacity: 0;
  padding: 1rem;
}

@keyframes blog-cta-fade-in {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.blog-cta-inner {
  max-width: 81rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  gap: 3rem;
}

.blog-cta-title {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.blog-cta-title h3 {
  margin: 0;
}

.blog-cta .btn {
  background-color: #fff;
  padding: 0.75rem 2rem;
  line-height: 1em;
  font-size: 1.5rem;
  border: 2px solid #212121;
  font-weight: 600;
  transition: all 0.4s ease;
}

.blog-cta .btn:hover {
  transform: scale(1.1);
}

.blog-cta-image {
  margin-top: -50%;
  margin-bottom: -1rem;
  font-size: 0;
}

.blog-cta-image > img {
  min-width: 14rem;
}

.blog-cta-close {
  padding: 1rem;
  transition: all 0.2s ease;
}

.blog-cta-close:hover {
  cursor: pointer;
  transform: rotate(135deg);
}

@media screen and (max-width: 800px) {
  .blog-cta-title {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .blog-cta-image {
    width: auto;
    margin-top: -6rem;
    margin-right: -1rem;
  }

  .blog-cta-title {
    width: 100%;
    padding: 0;
  }

  .blog-cta-close {
    padding: 0;
    width: 1rem;
    height: 1rem;
  }

  .blog-cta-inner {
    grid-template-columns: 30% 50% 5%;
    gap: 1.5rem;
    box-sizing: border-box;
    align-items: flex-start;
    padding: 1rem 0.5rem;
  }

  .blog-cta {
    padding: 0;
  }

  .blog-cta-image > img {
    min-width: 0;
    width: 100%;
  }

  .blog-cta .btn {
    font-size: 1rem;
    padding: 0.75rem;
    width: 100%;
  }
}
